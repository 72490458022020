import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class KeyManagementApi {
  rootPath: string = "/key-management";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.keyManagment');
  }

  createKey = (data: KeyManagementApi.CreateKey): Promise<Api.GetOne<KeyManagementApi.KeyDto>> =>
    this.instance.post(this.rootPath, data);
  getAll = (limit: number = 10,
    page: number = 1,
    query?: KeyManagementApi.GetKeyQuery): Promise<Api.GetAll<KeyManagementApi.KeyDto>> =>
    this.instance.get(this.rootPath, { params: {
      limit,
      skip: (page - 1) * limit,
      ...deleteEmptyFields(query || {}),
    }});
  getKey = (keyId: string): Promise<Api.GetOne<KeyManagementApi.KeyDto>> =>
    this.instance.get(`${this.rootPath}/${keyId}`);
  updateKey = (keyId: string, data: KeyManagementApi.UpdateKey): Promise<Api.GetOne<KeyManagementApi.KeyDto>> =>
    this.instance.put(`${this.rootPath}/${keyId}`, data);
  deleteKeys = (ids: string[]): Promise<unknown> =>
    this.instance.delete(this.rootPath, { params: { ids } });
  deleteKey = (keyId: string): Promise<unknown> =>
    this.instance.delete(`${this.rootPath}/${keyId}`);
  getPurposeKeys = (
    purposeId: string,
    limit: number = 10,
    page: number = 1,
    query?: KeyManagementApi.GetPurposeKeys
  ) =>
    this.instance.get(`${this.rootPath}/${purposeId}/lists`, { params: {
      limit,
      skip: (page - 1) * limit,
      ...deleteEmptyFields(query || {}),
    }});
  getBindiedOdjects = (keyId: string): Promise<Api.GetAll<KeyManagementApi.BindiedOdjectDto>> =>
    this.instance.get(`${this.rootPath}/${keyId}/bindings`);
  getPublicRSAKey = (keyId: string): Promise<Api.GetOne<KeyManagementApi.PublicKeyResponse>> =>
    this.instance.get(`${this.rootPath}/${keyId}/get-public-key`);
  setRSAKey = (keyId: string): Promise<Api.GetOne<KeyManagementApi.KeyDto>> => {
    try {
      return this.instance.patch(`${this.rootPath}/${keyId}/set-key`);
    } catch (e) {
      throw e;
    }
  }
  updateRSAKey = (keyId: string): Promise<Api.GetOne<KeyManagementApi.KeyDto>> => {
    try {
      return this.instance.patch(`${this.rootPath}/${keyId}/update-key`);
    } catch (e) {
      throw e;
    }
  }
}
